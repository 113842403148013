<template>
  <div>
    <v-list class="d-flex flex-wrap">
      <v-list-item v-for="cat in activeCategories" :key="cat.index">
        <v-list-item-title
          v-text="'-'.repeat(cat.index.split('.').length) + ' ' + cat[itemText]"
        ></v-list-item-title>
        <v-list-item-icon>
          <v-btn @click="unselectCategory(cat.index)" icon color="red">
            <v-icon>mdi-minus-circle</v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <v-autocomplete
      v-if="drugCategories.length > 0"
      ref="autocomplete"
      @change="selectedCategory"
      :items="drugCategories"
      :item-text="itemText"
      item-value="index"
      outlined
    ></v-autocomplete>
    <v-list class="d-flex flex-wrap" dense flat>
      <v-list-item-group>
        <v-list-item v-for="cat in activeCategories" :key="cat.index">
          <v-list-item-icon class="mr-2 mb-4">
            <v-icon @click="unselectCategory(cat.index)" color="red"
              >mdi-close-circle</v-icon
            >
          </v-list-item-icon>
          <v-list-item-content
            v-text="
              ''.repeat(cat.index.split('.').length) + ' ' + cat[$i18n.locale]
            "
          ></v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DrugCategoriesFilter",
  props: ["activeCategory", "forceLocale"],
  computed: {
    ...mapGetters("drugCategories", [
      "drugCategoriesByParent",
      "drugCategoryData",
      "drugCategoryParents"
    ]),
    drugCategories() {
      return this.drugCategoriesByParent(this.activeCategory);
    },
    activeCategories() {
      return this.drugCategoryParents(this.activeCategory);
    },
    itemText() {
      return this.forceLocale || this.$i18n.locale;
    }
  },
  methods: {
    unselectCategory: function(val) {
      if (val.split(".").length == 1) {
        this.$emit("selected-category", "");
      } else {
        this.$emit(
          "selected-category",
          val
            .split(".")
            .slice(0, -1)
            .join(".")
        );
      }
    },
    selectedCategory: function(val) {
      console.log(val);
      this.$emit("selected-category", val);
    }
  }
};
</script>
<style lang="scss" scoped>
.no-border button {
  border: none !important;
}
</style>
