<template>
  <div class="">
    <v-btn v-for="(button, index) in buttons" :key="button.key" rounded @click="buttonSelected(index, button)" :outlined="index != activeButtonIndex" color="primary" class="my-1"><v-icon>mdi-{{button.icon}}</v-icon>{{ button.text }}</v-btn>
  </div>
</template>

<script>
export default {
  name: "LikeRadioButtons",
  props: {
    activeButtonKey: {
        type: String,
        required: true
    },  
    buttons: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: () => ({
    activeButtonIndex: 0
  }),
  mounted() {
      let self = this;
      self.buttons.forEach(function callback(currentValue, index) {
          if (self.activeButtonKey == currentValue.key){
              self.activeButtonIndex = index
          }
      })
  },
  methods: {
      buttonSelected(index, button) {
          this.activeButtonIndex = index
          this.$emit('buttonSelected', button)
      }
  }
};
</script>